<div class="text-white">
    <app-header [welcomeText]="welcomeText"></app-header>
    <div class="container">
        <app-news></app-news>
        <app-numbers></app-numbers>
        <app-user-reviews></app-user-reviews>
        <app-rooms></app-rooms>
        <app-pictures></app-pictures>
    </div>
    <app-footer></app-footer>
</div>