<h1 mat-dialog-title>{{ dialogTitle }}</h1>
<div mat-dialog-content>
    <div class="title">
        Sélectionnez le mode d'affichage :
    </div>
    <mat-radio-group [formControl]="radio_displayMode_value" [color]="'primary'">
        <div>
            <mat-radio-button class="my-2" value="random">Aléatoirement</mat-radio-button>
        </div>
        <div>
            <mat-radio-button class="my-2" value="byRating">Selon la notation</mat-radio-button>
        </div>
    </mat-radio-group>
    <div class="row" *ngIf="radio_displayMode_value.value == 'byRating'">
        <div class="col-12">Choisir la plage de notation</div>
        <div class="col-1 pt-3">De</div>
        <mat-form-field class="col-5">
            <mat-label>Note minimale</mat-label>
            <mat-select [formControl]="select_minRate_value">
                <mat-option *ngFor="let someRate of ratingSelection" [value]="someRate.value">
                    {{ someRate.displayValue }} <i class="fas fa-star"></i>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="col-1 pt-3">à</div>
        <mat-form-field class="col-5">
            <mat-label>Note maximale</mat-label>
            <mat-select [formControl]="select_maxRate_value">
                <mat-option *ngFor="let someRate of ratingSelection" [value]="someRate.value">
                    {{ someRate.displayValue }} <i class="fas fa-star"></i>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-form-field appearance="fill">
        <mat-label>Nombre d'avis à afficher</mat-label>
        <input matInput type="number" placeholder="Ex. 4" [formControl]="input_numberToDisplay_value" max="20">
        <span matSuffix>avis</span>
        <mat-error *ngIf="input_numberToDisplay_value.invalid">Maximum : 20 avis</mat-error>
      </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ btn_cancel_content }}</button>
  <button mat-button (click)="btn_valid_click()" cdkFocusInitial>{{ btn_valid_content }}</button>
</div>