<h1 mat-dialog-title>{{ dialogTitle }}</h1>
<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Nombre de photos à afficher</mat-label>
        <input matInput type="number" placeholder="Ex. 4" [formControl]="input_numberToDisplay_value" max="20">
        <span matSuffix>avis</span>
        <mat-error *ngIf="input_numberToDisplay_value.invalid">Maximum : 20 avis</mat-error>
      </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ btn_cancel_content }}</button>
  <button mat-button (click)="btn_valid_click()" cdkFocusInitial>{{ btn_valid_content }}</button>
</div>