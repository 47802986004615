<app-sections-title [content]="title"></app-sections-title>
<div class="row">
    <div class="col-lg-3 col-md-6 px-5 py-1">
        <div class="parallelogram py-5">
            <div class="innerText text-center">
                <div class="bigNumber">{{ numberOfRooms }}</div>
                <div class="litleTitle">Salles</div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6 px-5 py-1">
        <div class="parallelogram py-5">
            <div class="innerText text-center">
                <div class="bigNumber">{{ numberOfCitys }}</div>
                <div class="litleTitle">Villes</div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6 px-5 py-1">
        <div class="parallelogram py-5">
            <div class="innerText text-center">
                <div class="bigNumber">{{ numberOfUsers }}</div>
                <div class="litleTitle">Membres</div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6 px-5 py-1">
        <div class="parallelogram py-5">
            <div class="innerText text-center">
                <div class="bigNumber openDays">7<sub>j</sub><sup>/7</sup></div>
                <div class="litleTitle">Ouvert</div>
            </div>
        </div>
    </div>
</div>
