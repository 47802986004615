<app-sections-title [content]="title"></app-sections-title>
<div class="row">
    <div class="col-md-3 col-sm-6 text-center mt-3 mb-3" *ngFor="let room of roomsList">
        <div>{{ room.nomVille }}</div>
        <div class="m-1">
            {{ room.nbSalles }} Salle<span *ngIf="room.nbSalles > 1">s</span>
        </div>
        <button (click)="btn_moreInformations_click(room.idVille, room.nomVille)"><i class="fas fa-info-circle"></i> {{ btn_moreInformations_content }}</button>
        <div class="mt-4">
            <div *ngFor="let someRoom of room.rooms">
                <div *ngIf="roomPodium != undefined">
                    <div *ngIf="roomPodium != null">
                        <div *ngFor="let someRoomPodium of roomPodium">
                            <div *ngIf="someRoomPodium.idSalle == someRoom.id && someRoom.afficherclassement" class="mt-3 border border-white rounded py-2">
                                Classement : {{ someRoom.nom }}
                                <div *ngFor="let somePodium of someRoomPodium.podium, index as i">
                                    <div class="d-flex justify-content-center">
                                        <table>
                                            <tr>
                                                <td rowspan="2">
                                                    <div class="h-100 d-flex align-items-center">
                                                        <span class="font-weight-bold bg-white text-danger rounded px-1 mr-1">
                                                            <span *ngIf="i == 0">1 er</span>
                                                            <span *ngIf="i == 1" class="font-weight-bold">2 ème</span>
                                                            <span *ngIf="i == 2" class="font-weight-bold">3 ème</span>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{{ somePodium.prenom }} {{ somePodium.nom.substr(0,1).toUpperCase() }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ somePodium.score }} pts</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
