<footer class="text-center w-100 p-5 mt-5 bg-dark">
    <div class="goToTop">
        <i class="material-icons" (click)="scrollToTop()">expand_less</i>
    </div>
    <div class="mb-2">
        <img [src]="footerLogo" width="200px">
    </div>
    {{ copyText }}
    <div class="m-3">
        <div><span (click)="btn_goToGestion_click()" class="text-decoration-none text-white login p-2 rounded"><i class="fas fa-user"></i> Espace gestion</span></div>
    </div>
</footer>