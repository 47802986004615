<div *ngIf="newsList != undefined">
    <div *ngIf="newsList != null && newsList.length > 0">
        <app-sections-title [content]="title"></app-sections-title>
        <div class="row">
            <div *ngFor="let news of newsList, index as i" class="mt-3 mb-3" [ngClass]="this.numberOfNews() % 2 != 0 && i == this.numberOfNews()-1 ? 'col-lg-6 offset-lg-3 col-md-12' : 'col-lg-6 col-md-12'">
                <div class="font-weight-bold">    
                    <ng-template [ngIf]="news.getStartDate() < news.getEndDate()" [ngIfElse]="onlyOneDay">
                        Du {{ dateToDisplay(news.getStartDate()) }} à {{ dateToDisplay(news.getEndDate()) }}
                    </ng-template>
                    <ng-template #onlyOneDay>
                        Le {{ dateToDisplay(news.getEndDate()) }}
                    </ng-template>
                </div>
                <div class="font-weight-bold my-1">
                    <div class="parallelogram px-2">
                        <div class="innerText">{{ news.getTitle() }}</div>
                    </div>
                </div>
                <div class="text-justify">{{ news.getDescription() }}</div>
            </div>
        </div>
    </div>
</div>