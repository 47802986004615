<div class="text-white">
    <app-header [welcomeText]="welcomeText"></app-header>
    <app-button class="btn-disconnect" [icon]="'work_off'" [content]="'Déconnexion'" (clickCusto)="disconnect()"></app-button>
    <div class="container h-100">
        <div class="row">
            <div class="col-md-6 col-sm-12 my-4" *ngFor="let element of editionElements">
                <a [routerLink]="element.path" class="text-decoration-none">
                    <div class="card text-center border-white text-white align-middle">
                        <div class="card-body text-uppercase">
                            {{ element.name }}
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>