<div class="text-white">
    <app-header [welcomeText]="welcomeText" [displayBackButton]="true"></app-header>
    <div class="container">
        <div class="row">
            <div class="col-6 offset-3 text-center align-content-center">
                <div class="title">
                    Mode d'affichage des podiums actuel :
                </div>
                <div class="rounded border border-light mt-3">
                    <table class="my-4 mx-auto">
                        <tr *ngFor="let someRoom of allRooms">
                            <td>{{ someRoom.nom }}</td>
                            <td class="pl-3 pr-1">
                                <mat-slide-toggle class="my-2" [checked]="someRoom.afficherclassement" (change)="changePodiumDisplayState(someRoom)"></mat-slide-toggle>
                            </td>
                            <td>
                                <span *ngIf="someRoom.afficherclassement">Activé</span>
                                <span *ngIf="!someRoom.afficherclassement">Désactivé</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>